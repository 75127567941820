import React, { Component } from 'react';
import { Layout } from './Components/Layout';
import './App.css';

export default class App extends Component {
    render() {
        return (
          <Layout />
        );
    }
}