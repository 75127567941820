import { Component } from "react";
import { BungieNewsEnvelope, BungieNewsItem, BungieNewsLineItem } from "./BungieNewsItem";
import { Document as DocumentSearch } from "flexsearch";
import "./Main.css";

export class BungieSearchResult {
    public documentId: string | undefined;
    public documentSortKey: string | undefined;
    public title: string | undefined;
    public uri: string | undefined;
    public lines: BungieSearchResultLine[] | undefined;
}

export class BungieSearchResultLine {
    public text: string | undefined;
    public startText: string | undefined;
    public searchText: string | undefined;
    public endText: string | undefined;
    public highlight: boolean | undefined;
    public nestedLines: BungieSearchResultLine[] | undefined;
}

export default class Main extends Component<{ searchText: string, filterText: string }, { documents: { [k: string]: BungieNewsItem }, lines: { [k: string]: BungieNewsLineItem }, loading: boolean }> {
    private searchContext = new DocumentSearch<BungieNewsLineItem>({
        preset: "performance",
        tokenize: "forward",
        worker: true,
        matcher: {
            û: "u",
        },
        document: {
            id: "id",
            index: ["text"],
        },
    });

    constructor(props: { searchText: string, filterText: string }) {
        super(props);
        this.state = {
            documents: {},
            lines: {},
            loading: true
        };
    }

    componentDidMount() {
        this.populateNews();
    }

    render() {
        const searchText = this.props.searchText;
        const filterText = this.props.filterText;

        let results: BungieSearchResult[] = [];
        let noSearchResults = "Search for something to display results";

        if (!this.state.loading) {
            if (!!searchText && searchText.length > 2) {
                let searchResults = this.searchContext.search(searchText)[0];

                let groupedResults: { [k: string]: BungieNewsItem } = {};
                if (!!searchResults) {
                    var sortedKeys = (searchResults.result as string[]).sort((a, b) => {
                        let aDocId = parseInt(a.split("~")[2]);
                        let bDocId = parseInt(b.split("~")[2]);
                        let aLineId = parseInt(a.split("~")[1]);
                        let bLineId = parseInt(b.split("~")[1]);

                        if (aDocId > bDocId) {
                            return -1;
                        }
                        else if (aDocId < bDocId) {
                            return 1;
                        }
                        else if (aLineId < bLineId) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    });

                    for (var document_line_key of sortedKeys) {
                        let line = this.state.lines[document_line_key];
                        let document = this.state.documents[line.documentId ?? ""];

                        if (!groupedResults[line.documentId ?? ""]) {
                            groupedResults[line.documentId ?? ""] = {
                                documentId: line.documentId,
                                uri: document.uri,
                                title: document.title,
                                lines: [],
                                publishDate: document.publishDate,
                                articleType: document.articleType,
                                documentSortKey: document.documentSortKey,
                                updatedDate: document.updatedDate
                            };
                        }

                        groupedResults[line.documentId ?? ""].lines?.push(line);
                    }

                    for (var actualKey in groupedResults) {
                        let document = groupedResults[actualKey];

                        if (!filterText ||
                            filterText === "all" ||
                            (filterText === "twab" && document.articleType === "TWAB") ||
                            (filterText === "patchnotes" && document.articleType === "PATCHNOTES")) {

                            let searchItem: BungieSearchResult = {
                                documentId: document.documentId,
                                documentSortKey: document.documentSortKey,
                                uri: document.uri,
                                title: document.title,
                                lines: []
                            };

                            let displayedLines: string[] = [];

                            for (var resultLine of document.lines ?? []) {
                                let lineToProcess = resultLine;
                                if (!!resultLine.parentLineId) {
                                    let parentKey = document.documentId + "~" + resultLine.parentLineId + "~" + resultLine.documentSortKey;
                                    lineToProcess = this.state.lines[parentKey];
                                }

                                if (displayedLines.indexOf(resultLine.lineId ?? "") > 0) {
                                    continue;
                                }

                                displayedLines.push(lineToProcess.lineId ?? "");
                                let nestedLines: BungieSearchResultLine[] | undefined = undefined;
                                if (!!lineToProcess.childLineIds) {
                                    nestedLines = [];

                                    for (let childLineId of lineToProcess.childLineIds) {
                                        let key = document.documentId + "~" + childLineId + "~" + document.documentSortKey;
                                        let childLine = this.state.lines[key];
                                        var searchPosition = (childLine.text?.toLowerCase().indexOf(searchText.toLowerCase()) ?? -1);
                                        var highlight = searchPosition >= 0;
                                        nestedLines.push({
                                            text: highlight ? undefined : childLine.text,
                                            nestedLines: undefined,
                                            highlight: highlight,
                                            startText: highlight ? childLine?.text?.substring(0, searchPosition) : undefined,
                                            searchText: highlight ? childLine?.text?.substring(searchPosition, searchPosition + searchText.length) : undefined,
                                            endText: highlight ? childLine?.text?.substring(searchPosition + searchText.length) : undefined
                                        });
                                        displayedLines.push(childLineId);
                                    }
                                }

                                var parentSearchPosition = (lineToProcess.text?.toLowerCase().indexOf(searchText.toLowerCase()) ?? -1);
                                var parentHighlight = parentSearchPosition >= 0;
                                searchItem.lines?.push({
                                    text: parentHighlight ? undefined : lineToProcess.text,
                                    nestedLines: nestedLines,
                                    highlight: parentHighlight,
                                    startText: parentHighlight ? lineToProcess?.text?.substring(0, parentSearchPosition) : undefined,
                                    searchText: parentHighlight ? lineToProcess?.text?.substring(parentSearchPosition, parentSearchPosition + searchText.length) : undefined,
                                    endText: parentHighlight ? lineToProcess?.text?.substring(parentSearchPosition + searchText.length) : undefined
                                });
                            }

                            results.push(searchItem);
                        }
                    }
                }
                else {
                    noSearchResults = "No results found for '" + searchText + "'";
                }
            }
        }

        results = results.sort((a, b) => parseInt(a.documentSortKey ?? "0") < parseInt(b.documentSortKey ?? "0") ? 1 : -1);

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : results.length === 0
            ? <div className="searchError">{noSearchResults}</div>
            : results.map(item =>
                <div>
                    <a href={item.uri} target="_blank" rel="noreferrer" className="itemLink">
                        <h2>{item.title}</h2>
                        <ul>
                            {item.lines?.map(line => {
                                return !line.nestedLines
                                    ? <li className="foundLine">{line.highlight ? <span>{line.startText}<mark>{line.searchText}</mark>{line.endText}</span> : line.text}</li>
                                    : <li className="foundLine">{line.highlight ? <span>{line.startText}<mark>{line.searchText}</mark>{line.endText}</span> : line.text}<ul>{line.nestedLines.map(child => <li>{child.highlight ? <span>{child.startText}<mark>{child.searchText}</mark>{child.endText}</span> : child.text}</li>)}</ul></li>
                            })}
                        </ul>
                    </a>
                    <hr />
                </div>
            );

        return (
            <div className="mainContent">
                {contents}
            </div>
        );
    }

    async populateNews() {
        let response = await fetch("BungieNews")
        let data: BungieNewsEnvelope = await response.json();
        let items: BungieNewsItem[] = [];
        items = items.concat(data.value ?? []);

        while (!!data.continuationToken) {
            response = await fetch("BungieNews?skipToken=" + data.continuationToken);
            data = await response.json();
            items = items.concat(data.value ?? []);
        }

        const documents: { [k: string]: BungieNewsItem } = {};
        items.forEach((item, itemIndex) => {
            let key = item.documentId ?? itemIndex.toString();
            documents[key] = item;
        });

        const rawLines: BungieNewsLineItem[] = [];
        for (const document of items) {
            if (!!document?.lines) {
                document?.lines?.forEach(line => {
                    line.documentId = document.documentId;
                    line.documentSortKey = document.documentSortKey;
                    line.id = document.documentId + "~" + line.lineId + "~" + document.documentSortKey;
                    rawLines.push(line);
                });
            }
        }

        const lines: { [k: string]: BungieNewsLineItem } = {};
        rawLines.forEach((item, itemIndex) => {
            let key = item.id ?? itemIndex.toString();
            lines[key] = item;
        });

        rawLines.forEach(line => this.searchContext.add(line));

        this.setState({
            documents: documents,
            lines: lines,
            loading: false
        });
    }
}