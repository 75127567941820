import { Component } from "react";
import "./Header.css";

export default class Header extends Component<{ onSearchChange: (arg0: string) => void, onFilterChange: (arg0: string) => void }, { searchText: string, filterText: string }> {
    constructor(props: any) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleSearchChange(e: any) {
        this.props.onSearchChange(e.target.value);
    }

    handleFilterChange(e: any) {
        this.props.onFilterChange(e.target.value);
    }

    render() {
        return (
            <div className="header">
                <div className="title">
                    <a href="/">Destiny News Archive</a>
                </div>

                <div className="inputs">
                    <div className="filterBox">
                        <label>Filter:</label>
                        <select name="articleType" id="articleType" onChange={this.handleFilterChange}>
                            <option value="all">All</option>
                            <option value="twab">TWABs</option>
                            <option value="patchnotes">Patch Notes</option>
                        </select>
                    </div>

                    <div className="searchBox">
                        <label>Search:</label>
                        <input className="searchInput" onChange={this.handleSearchChange}></input>
                    </div>
                </div>
            </div>
        );
    }
}