import { Component } from "react";
import Header from "./Header/Header";
import Main from "./Main/Main";

export class Layout extends Component<{}, { searchText: string, filterText: string }> {

    constructor(props: any) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.state = { searchText: "", filterText: "all" };
    }

    handleSearchChange(searchText: string) {
        this.setState({ searchText: searchText });
    }

    handleFilterChange(filterText: string) {
        this.setState({ filterText: filterText })
    }

    render() {
        const search = this.state.searchText;
        const filter = this.state.filterText
        return (
            <div>
                <Header onSearchChange={this.handleSearchChange} onFilterChange={this.handleFilterChange} />
                <Main searchText={search} filterText={filter} />
            </div>
        );
    }
}